<template>
  <div class="header-modal">
    <div
      v-if="!showSchoolUploadDocument"
      class="header-modal__wrapper"
    >
      <div class="header-modal__inner">
        <img
          src="/v2/header/old/close.svg"
          alt="close"
          class="header-modal__close"
          @click="closeHeaderModal"
        >
        <h6
          class="header-modal__title"
          :class="[getModalData.className]"
          v-html="getModalData.title"
        />

        <p
          v-if="getModalData.description"
          class="header-modal__description"
        >
          {{ getModalData.description }}
        </p>

        <div class="header-modal__fields">
          <form-name-field
            ref="nameField"
            v-model="nameInput"
            class="form__input"
            placeholder="Имя"
          />

          <form-phone-field
            ref="phoneField"
            class="form__input form__input_phone"
            :errors-list="submitError"
            :error="validForm === false"
            @updatePhone="phoneInput = $event"
          />

          <form-email-field
            ref="emailField"
            v-model="emailInput"
            class="form__input form__input_email"
            :submit-error="submitError"
            placeholder="Почта"
            @clearSubmitError="clearSubmitError"
          />
        </div>

        <button
          :class="['header-modal__btn', getModalData.className]"
          @click="getModalData.register ? signUp() : submit()"
        >
          {{ getModalData.button }}
        </button>

        <p class="header-modal__law">
          Нажимая на кнопку, вы соглашаетесь
          <br>
          <nuxt-link
            to="/privacy"
            target="_blank"
          >
            c условиями обработки данных
          </nuxt-link>
        </p>

        <div class="header-modal__social">
          <p>
            Или напишите нам в чат:
          </p>

          <div class="header-modal__social-items">
            <a
              href="https://wa.me/79686286327"
              target="_blank"
              rel="nofollow"
            >
              <img
                src="/v2/header/old/dropdown/whatsapp.svg"
                alt="whatsapp"
              >
            </a>
            <a
              href="https://t.me/schoolsynergy_bot"
              target="_blank"
              rel="nofollow"
            >
              <img
                src="/v2/header/old/dropdown/telegram.svg"
                alt="telegram"
              >
            </a>
            <a
              href="mailto:nstolbova@synergy.ru"
              target="_blank"
              rel="nofollow"
            >
              <img
                src="/v2/header/old/dropdown/email.svg"
                alt="email"
              >
            </a>
          </div>
        </div>
      </div>

      <img
        v-if="getModalData.sticker"
        :src="getModalData.sticker"
        alt="sticker"
        class="header-modal__sticker"
      >
    </div>

    <div
      v-if="showSchoolUploadDocument"
      class="header-modal__inner"
    >
      <img
        src="/v2/header/old/close.svg"
        alt="close"
        class="header-modal__close"
        @click="closeHeaderModal"
      >

      <p class="header-modal__ready">
        Готово!
      </p>

      <h6 class="header-modal__title">
        Спасибо, что заполнили форму, скачивание скоро начнется
      </h6>

      <img
        src="/v2/header/old/success.png"
        alt="success"
        class="header-modal__success"
      >

      <p class="header-modal__law">
        Если скачивание не началось, то можете скачать .pdf файл
        <a
          href="/v2/docs/header/Чек лист для поступления в онлайн-школу.pdf"
          target="_blank"
          download="Чек лист для поступления в онлайн-школу.pdf"
        >
          по этой ссылке
        </a>
      </p>
    </div>

    <div
      class="header-modal__overlay"
      @click="closeHeaderModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FormNameField from '@/components/v2/common/fileds/FormNameField.vue';
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';
import sendLeadMixinConstructor from '@/mixins/sendLeadMixinConstructor';
import { getQuestionnairePath } from '@/utils/questionnairePath';

const sendLeadMixin = sendLeadMixinConstructor({
  needPhone: true,
  needEmail: true,
  needToSendTitle: true,
  needToSendComments: true,
});

export default {
  name: 'AppHeaderModalOld',
  components: {
    FormPhoneField,
    FormEmailField,
    FormNameField,
  },
  mixins: [sendLeadMixin],

  data: () => ({
    showSchoolUploadDocument: false,
  }),
  computed: {
    ...mapGetters('headerModals', {
      freeSchoolLeadModal: 'freeSchoolLeadModal',
      freeSchoolDocumentsLeadModal: 'freeSchoolDocumentsLeadModal',
      freeExternalLeadModal: 'freeExternalLeadModal',
      freeEgeOgeLeadModal: 'freeEgeOgeLeadModal',
      freeEgeOgeProfessionalLeadModal: 'freeEgeOgeProfessionalLeadModal',
      freeTutorsLeadModal: 'freeTutorsLeadModal',
      freeTutorsJuniorLeadModal: 'freeTutorsJuniorLeadModal',
      freeChildLeadModal: 'freeChildLeadModal',
      freeChildProfessionalLeadModal: 'freeChildProfessionalLeadModal',
      freeChildBlogLeadModal: 'freeChildBlogLeadModal',
      freeChildLanguageLeadModal: 'freeChildLanguageLeadModal',
      freeChildPersonalLeadModal: 'freeChildPersonalLeadModal',
      freeITLeadModal: 'freeITLeadModal',
      freeITFutureLeadModal: 'freeITFutureLeadModal',
      freeITProgrammingLeadModal: 'freeITProgrammingLeadModal',
      freeFreeLeadModal: 'freeFreeLeadModal',
      freeFreeConsultationLeadModal: 'freeFreeConsultationLeadModal',
    }),
    getModalData() {
      if (this.freeSchoolLeadModal) {
        return {
          title: 'Запишитесь на <span class="school"><span>бесплатный</span></span> пробный день в школе',
          description: 'С вами свяжется отдел заботы и выберет подходящий день',
          button: 'Записаться',
          className: 'school',
        };
      }
      if (this.freeSchoolDocumentsLeadModal) {
        return {
          title: 'Заполните данные и скачайте чек-лист с документами и инструкцией',
          description: null,
          button: 'Скачать',
          className: 'school',
        };
      }
      if (this.freeExternalLeadModal) {
        return {
          title: 'Запишитесь на <span class="external"><span>бесплатный</span></span> пробный день в школе',
          description: 'С вами свяжется отдел заботы и выберет подходящий день',
          button: 'Записаться',
          className: 'external',
        };
      }
      if (this.freeEgeOgeLeadModal) {
        return {
          title: 'Запишитесь на <span class="egeoge"><span>бесплатный</span></span> пробный урок',
          description: 'С вами свяжется отдел заботы и выберет подходящий день',
          button: 'Записаться',
          className: 'egeoge',
        };
      }
      if (this.freeEgeOgeProfessionalLeadModal) {
        return {
          // eslint-disable-next-line max-len,vue/max-len
          title: 'Откройте дверь в мир новых возможностей через <span class="egeoge"><span>профориентацию</span></span>',
          description: null,
          button: 'Оставить заявку',
          className: 'egeoge',
        };
      }
      if (this.freeTutorsLeadModal) {
        return {
          title: 'Запишитесь на бесплатный урок по любому предмету',
          description: 'С вами свяжется отдел заботы, чтобы выбрать предмет и удобное время',
          button: 'Записаться на урок',
          className: 'tutors',
        };
      }
      if (this.freeTutorsJuniorLeadModal) {
        return {
          title: 'Подготовим вас и вашего ребенка к первому классу',
          description: 'С вами свяжется отдел заботы и подробно расскажет о программе',
          button: 'Отправить заявку',
          className: 'tutors',
        };
      }
      if (this.freeChildLeadModal) {
        return {
          title: 'Запишитесь на <span class="child"><span>бесплатную</span></span> диагностику гибких навыков',
          description: 'С вами свяжется отдел заботы и назначит удобное время',
          button: 'Записаться',
          className: 'child',
        };
      }
      if (this.freeChildProfessionalLeadModal) {
        return {
          title: 'Откройте дверь в мир новых возможностей через <span class="child"><span>профориентацию</span></span>',
          description: null,
          button: 'Оставить заявку',
          className: 'child',
        };
      }
      if (this.freeChildBlogLeadModal) {
        return {
          title: 'Оставьте заявку и получите доступ к <span class="child"><span>первому занятию</span></span>',
          description: 'С вами свяжется отдел заботы и поможет подобрать курс',
          button: 'Отправить',
          className: 'child',
        };
      }
      if (this.freeChildLanguageLeadModal) {
        return {
          title: 'Посетите первый пробный урок <span class="child"><span>бесплатно</span></span>',
          description: 'Научим языку с нуля и заинтересуем ребенка английским или китайским',
          button: 'Записаться',
          className: 'child',
        };
      }
      if (this.freeChildPersonalLeadModal) {
        return {
          title: '<span class="child"><span>Узнайте детали</span></span> и запишитесь к наставнику-тьютору',
          description: 'С вами свяжется отдел заботы и поможет записаться',
          button: 'Записаться',
          className: 'child',
        };
      }
      if (this.freeITLeadModal) {
        return {
          title: 'Оставьте заявку и получите доступ к <span class="it"><span>первому занятию</span></span>',
          description: 'С вами свяжется отдел заботы и поможет подобрать курс',
          button: 'Отправить',
          className: 'it',
        };
      }
      if (this.freeITFutureLeadModal) {
        return {
          title: 'Открыта <span class="it"><span>предзапись</span></span> на бесплатное обучение',
          description: 'С вами свяжется отдел заботы и ответит на все вопросы',
          button: 'Отправить',
          className: 'it',
        };
      }
      if (this.freeITProgrammingLeadModal) {
        return {
          title: 'Оставьте заявку, а мы <span class="it"><span>поможем подобрать</span></span> курс',
          description: 'Сотрудник отдела заботы поможет определиться с курсом, подходящим ребенку по возрасту и уровню',
          button: 'Отправить',
          className: 'it',
          sticker: '/v2/header/old/modal-it-sticker.png',
        };
      }
      if (this.freeFreeLeadModal) {
        return {
          // eslint-disable-next-line max-len,vue/max-len
          title: 'Оставьте заявку и получите <span class="free"><span>бесплатный</span></span> доступ к платформе на 7 дней',
          description: null,
          button: 'Получить',
          className: 'free',
          register: true,
        };
      }
      if (this.freeFreeConsultationLeadModal) {
        return {
          title: 'Запишитесь на <span class="free"><span>бесплатную</span></span> встречу с куратором школы',
          description: 'С вами свяжется отдел заботы и назначит удобное время',
          button: 'Получить',
          className: 'free',
        };
      }

      return {};
    },
    titleToSend() {
      return this.getModalData.title.replace(/<\/?[^>]+(>|$)/g, '');
    },
    commentsToSend() {
      if (this.freeTutorsLeadModal) {
        return 'Попробуйте любого репетитора за 0 рублей';
      }
      return '';
    },
  },

  methods: {
    ...mapActions('headerModals', [
      'closeHeaderModal',
    ]),
    onSubmit() {
      if (this.freeSchoolDocumentsLeadModal) {
        this.showSchoolUploadDocument = true;

        const url = '/v2/docs/header/Чек лист для поступления в онлайн-школу.pdf';
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Чек лист для поступления в онлайн-школу.pdf';

        const clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: false,
        });

        link.dispatchEvent(clickEvent);
      } else {
        this.closeHeaderModal();
        // this.$router.push('/thanks');
        this.$router.push(getQuestionnairePath(this.$route));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$school: #FF7233;
$external: #61DDBC;
$egeoge: #67ADFF;
$tutors: #B3A4EE;
$child: #FE9CE0;
$it: #FF5151;
$free: #B4DF80;

.header-modal {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    width: 545px;
    max-height: 98vh;
    position: relative;

    @include media-down(tablet) {
      width: 100%;
      max-width: 344px;
    }
  }

  &__inner {
    width: 545px;
    max-height: 98vh;
    overflow: auto;
    box-sizing: border-box;
    padding: 40px;
    position: relative;
    z-index: 1;

    border-radius: 20px;
    border: none;
    background: $white-color;
    box-shadow: 4px 4px 20px 0 rgba(#071539, .05), 10px 10px 50px 0 rgba(#071539, .1);

    @include media-down(tablet) {
      width: 100%;
      max-width: 344px;
      padding: 40px 8px;
    }
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;

    cursor: pointer;

    @include media-down(tablet) {
      right: 0;
      top: 0;
    }
  }

  &__title {
    text-align: center;
    font-size: 34px;
    font-weight: 800;
    line-height: 37.4px;
    letter-spacing: -1.2px;
    color: $black-color;

    @include media-down(tablet) {
      font-size: 24px;
      line-height: 26.4px;
      letter-spacing: -0.48px;
    }

    ::v-deep & > span {
      display: inline-block;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 10px;
        background-color: $school;
        position: absolute;
        left: 0;
        bottom: 0;

        @include media-down(tablet) {
          height: 8px;
        }
      }

      span {
        position: relative;
        z-index: 2;
      }
    }
    span.school &:before {
      background-color: $school;
    }
    ::v-deep span.external {
      &:before {
        background-color: $external;
      }
    }
    ::v-deep span.egeoge {
      &:before {
        background-color: $egeoge;
      }
    }
    ::v-deep span.tutors {
      &:before {
        background-color: $tutors;
      }
    }
    ::v-deep span.child {
      &:before {
        background-color: $child;
      }
    }
    ::v-deep span.it {
      &:before {
        background-color: $it;
      }
    }
    ::v-deep span.free {
      &:before {
        background-color: $free;
      }
    }
  }

  &__description {
    max-width: 414px;
    margin: 12px auto 0;

    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    color: #919191;

    @include media-down(tablet) {
      margin: 8px auto 0;

      font-size: 18px;
      font-weight: 600;
      line-height: 100%;
    }
  }

  &__fields {
    margin-top: 32px;

    @include media-down(tablet) {
      margin-top: 24px;
    }

    .form__input {
      margin: 0 auto 10px;

      @include media-down(tablet) {
        margin-bottom: 8px;
      }

      ::v-deep input:not(.phone-field__input) {
        width: 100%;
        height: auto;
        padding: 23.25px 18px;

        border-radius: 100px;
        border: 2px solid rgba($black-color, .2);

        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.4px;
        line-height: 1;
        color: $black-color;

        &::placeholder {
          color: $grey2;
        }

        @include media-down(tablet) {
          padding: 19px 14px 20px;

          font-size: 18px;
        }
      }

      ::v-deep .phone-field__input {
        padding-top: 23.75px;
        padding-bottom: 26px;

        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.4px;

        @include media-down(tablet) {
          font-size: 18px;
        }
      }

      ::v-deep .phone-field__wrap {
        height: 74px;
        padding: 26px 20px 28px 20px;

        border-radius: 100px;
        border: 2px solid rgba($black-color, .2);

        @include media-down(tablet) {
          height: 64px;
        }

        input {
          border-radius: inherit;

          &::placeholder {
            color: $grey2;
          }
        }

        &--error {
          border-color: #ff0040;
        }
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-top: 20px;

    border-radius: 100px;
    border: 2px solid $black-color;
    background-color: $school;
    transform: translate(-4px, -4px);
    box-shadow: 4px 4px 0 0 $black-color;

    font-family: 'TildaSans', sans-serif;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    color: $white-color;

    cursor: pointer;
    transition: transform .2s ease-out, box-shadow .2s ease-out;

    @include media-down(tablet) {
      margin-top: 8px;
      height: 60px;

      transform: translate(-4px, -4px);
      box-shadow: 4px 4px 0 0 $black-color;

      font-size: 18px;
    }

    &:hover {
      transform: translate(0, -0);
      box-shadow: 0 0 0 0 $black-color;
    }
  }
  &__btn.school {
    background-color: $school;
    border: 2px solid darken($school, 10%);

    box-shadow: 4px 4px 0 0 darken($school, 10%);

    &:hover {
      box-shadow: 0 0 0 0 darken($school, 10%);
    }
  }
  &__btn.external {
    background-color: $external;
    border: 2px solid darken($external, 10%);

    box-shadow: 4px 4px 0 0 darken($external, 10%);

    &:hover {
      box-shadow: 0 0 0 0 darken($external, 10%);
    }
  }
  &__btn.egeoge {
    background-color: $egeoge;
    border: 2px solid darken($egeoge, 10%);

    box-shadow: 4px 4px 0 0 darken($egeoge, 10%);

    &:hover {
      box-shadow: 0 0 0 0 darken($egeoge, 10%);
    }
  }
  &__btn.tutors {
    background-color: $tutors;
    border: 2px solid darken($tutors, 10%);

    box-shadow: 4px 4px 0 0 darken($tutors, 10%);

    &:hover {
      box-shadow: 0 0 0 0 darken($tutors, 10%);
    }
  }
  &__btn.child {
    background-color: $child;
    border: 2px solid darken($child, 10%);

    box-shadow: 4px 4px 0 0 darken($child, 10%);

    &:hover {
      box-shadow: 0 0 0 0 darken($child, 10%);
    }
  }
  &__btn.it {
    background-color: $it;
    border: 2px solid darken($it, 10%);

    box-shadow: 4px 4px 0 0 darken($it, 10%);

    &:hover {
      box-shadow: 0 0 0 0 darken($it, 10%);
    }
  }
  &__btn.free {
    background-color: $free;
    border: 2px solid darken($free, 10%);

    box-shadow: 4px 4px 0 0 darken($free, 10%);

    &:hover {
      box-shadow: 0 0 0 0 darken($free, 10%);
    }
  }

  &__law {
    max-width: 384px;
    margin: 10px auto 0;

    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 18px;
    font-weight: 600;
    line-height: 100%;
    color: $black-color;

    @include media-down(tablet) {
      margin-top: 8px;

      font-size: 16px;
      line-height: 120%;
    }

    a {
      color: $header-dark-orange;
    }
  }

  &__social {
    margin-top: 35px;

    @include media-down(tablet) {
      margin-top: 26px;
    }

    p {
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;
      text-align: center;
      color: $black-color;

      @include media-down(tablet) {
        font-size: 20px;
        font-weight: 600;
        line-height: 100%;
      }
    }

    &-items {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;

      a {
        display: inline-block;
        width: 50px;
        height: 50px;
        overflow: hidden;

        border: 2px solid $black-color;
        border-radius: 10px;
        box-shadow: 2px 2px 0 0 $black-color;
        transform: translate(-2px, -2px);

        transition: transform .2s ease-out, box-shadow .2s ease-out;

        &:nth-of-type(1) {
          border: 2px solid #168B25;
          box-shadow: 2px 2px 0 0 #168B25;
        }
        &:nth-of-type(2) {
          border: 2px solid #016EA3;
          box-shadow: 2px 2px 0 0 #016EA3;
        }
        &:nth-of-type(3) {
          border: 2px solid #044396;
          box-shadow: 2px 2px 0 0 #044396;
        }

        &:hover {
          transform: translate(0, 0);
          box-shadow: 0 0 0 0 $black-color;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  &__sticker {
    position: absolute;
    width: 228px;
    right: -48px;
    bottom: -26px;
    z-index: 1;

    @include media-down(tablet) {
      right: -25px;
      bottom: -60px;
    }
  }

  &__ready {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 130%;
    color: #89C053;

    @include media-down(tablet) {
      font-size: 18px;
      line-height: 100%;
    }
  }

  &__success {
    display: block;
    width: 202px;
    margin: 40px auto;

    @include media-down(tablet) {
      width: 170px;
      margin: 32px auto;
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-color: rgba($white-color, 0.6);
    -webkit-backdrop-filter: blur(2.5px);
    backdrop-filter: blur(2.5px);

    cursor: pointer;
  }
}
</style>
