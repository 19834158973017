<template>
  <div
    class="header"
    @mouseleave="closeDropdownByMouseleave"
  >
    <div class="header__inner">
      <header
        id="headerComponent"
        class="header__container"
      >
        <div class="header__logo">
          <nuxt-link
            class="header__logo-link"
            to="/"
          >
            <img
              class="header__logo"
              src="/v2/header/old/logo.svg"
              alt="logo"
              :width="isTablet ? '111px' : '162px'"
              :height="isTablet ? '26.72px' : '39px'"
            >
          </nuxt-link>
        </div>

        <ul class="header__list">
          <li @mouseover="openDropdownByMouseover">
            <button
              class="m-btn"
              @mouseover="openDropdownByMouseover"
              @keydown.enter="toggleDropdown"
            >
              Каталог
            </button>
            <img
              src="/v2/header/old/arrow.svg"
              alt="arrow"
              :class="{ 'active': showDropdown }"
            >
          </li>
          <li>
            <button
              class="m-btn"
              @click="$store.dispatch('modals/toggleLead9')"
            >
              <span>Скидки</span>
            </button>
          </li>
          <li>
            <nuxt-link to="/online-school">
              <span>О школе</span>
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/otzyvy">
              <span>Отзывы</span>
            </nuxt-link>
          </li>
        </ul>

        <div class="header__buttons">
          <a
            href="https://t.me/schoolsynergy_bot"
            target="_blank"
            rel="nofollow"
            class="btn"
          >
            <img
              src="/v2/header/old/telegram-simple.svg"
              alt="telegram"
            >
            <span>Написать</span>
          </a>

          <a
            :href="`tel:${phone}`"
            target="_blank"
            class="btn"
          >
            <img
              src="/v2/header/old/phone.svg"
              alt="phone"
            >
            <span>{{ phone | prettyPhone }}</span>
          </a>

          <button
            class="btn login"
            @click="openSignIn"
          >
            <img
              v-show="user"
              src="/v2/header/old/user-auth.svg"
              alt="user auth"
            >
            <img
              v-show="!user"
              src="/v2/header/old/user.svg"
              alt="user"
            >
            <span>{{ user ? isDesktop ? 'ЛК' : 'Личный кабинет' : 'Войти' }}</span>
          </button>

          <button
            class="btn dropdown"
            @click="showDropdown = !showDropdown"
          >
            <span :class="{ 'active': showDropdown }" />
            <span :class="{ 'active': showDropdown }" />
            <span :class="{ 'active': showDropdown }" />
          </button>
        </div>

        <transition name="fade">
          <dropdown-menu v-if="showDropdown && !isTablet" />
        </transition>

        <transition name="fade">
          <dropdown-menu-mobile v-if="showDropdown && isTablet" />
        </transition>
      </header>
    </div>

    <transition name="fade">
      <div
        v-if="showDropdown && isTablet"
        class="header__overlay"
        @click="showDropdown = false"
      />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DropdownMenu from './AppHeaderComponents/DropdownMenu.vue';
import DropdownMenuMobile from './AppHeaderComponents/DropdownMenuMobile.vue';

export default {
  name: 'AppHeaderOld',
  components: {
    DropdownMenu,
    DropdownMenuMobile,
  },

  data: () => ({
    showDropdown: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user',
      isTablet: 'isTablet',
      isDesktop: 'isDesktop',
    }),
    ...mapGetters('modals', {
      videoModal: 'videoModal',
    }),
    routeName() {
      return this.$route.name;
    },
    phone() {
      return process.env.phone;
    },
  },
  watch: {
    routeName() {
      if (this.showDropdown) {
        this.showDropdown = false;
      }
    },
    videoModal(newVal) {
      if (newVal && this.showDropdown) {
        this.showDropdown = false;
      }
    },
  },

  methods: {
    ...mapActions('modals', {
      toggleAuthModal: 'toggleAuth',
    }),
    async openSignIn() {
      if (this.showDropdown) {
        this.showDropdown = false;
      }

      if (this.$cookies.get('auth')) {
        const user = await this.$api.getMe();

        if (user) {
          window.location = '/classroom';
          return;
        }

        this.$cookies.remove('auth');
      }

      this.toggleAuthModal();
      this.burgerState = false;
    },
    openDropdownByMouseover() {
      if (!this.isTablet && !this.showDropdown) {
        this.showDropdown = true;
      }
    },
    closeDropdownByMouseleave() {
      if (!this.isTablet && this.showDropdown) {
        this.showDropdown = false;
      }
    },
    toggleDropdown() {
      if (this.showDropdown) {
        this.closeDropdownByMouseleave();
      } else {
        this.openDropdownByMouseover();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 50;
  margin-bottom: -80px;

  @include media-down(tablet) {
    margin-bottom: -48px;
  }

  &__inner {
    position: relative;
    z-index: 51;
    top: 10px;

    padding: 0 16px;

    width: 100%;

    @include media-down(tablet) {
      padding: 0 8px;
      top: 8px;
    }
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    margin: 0 auto;
    padding: 10px;

    max-width: 1260px;
    height: 60px;

    border: 2px solid $gray-bg;
    border-radius: 100px;

    background: $white-color;

    @include media-down(laptop) {
      padding: 8px;
    }

    @include media-down(tablet) {
      height: 48px;
      padding-right: 2px;

      border-radius: 24px;
      border: 2px solid $black-color;
      box-shadow: 2px 4px 0 0 $black-color;
    }
  }

  &__logo {
    img {
      @include media-down(tablet) {
        width: 111px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    gap: 40px;
    margin-left: -100px;
    list-style-type: none;

    @media (max-width: 1200px) {
      margin-left: 0;
      gap: 20px;
    }

    @include media-down(tablet) {
      display: none;
    }

    li {
      display: inline-block;
      position: relative;

      cursor: pointer;

      a,
      button {
        display: inline-block;

        color: $black-color;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 22px;
        font-weight: 600;
        line-height: 100%;
      }

      img {
        margin-left: 2px;
        margin-bottom: 2px;
        transition: transform .2s ease-out, margin-bottom .2s ease-out;
      }
      img.active {
        transform: rotate(180deg);
        margin-bottom: 3px;
      }

      span {
        display: inline-block;
        position: relative;

        &:before {
          content: '';
          display: block;
          width: 0;
          height: 2px;
          position: absolute;
          left: 0;
          bottom: -2px;

          background-color: $black-color;

          transition: width .2s ease-out;
        }
      }

      &:hover {
        span {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;

    @include media-down(tablet) {
      gap: 2px;
    }

    .btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 1px;
      width: auto;
      height: 40px;
      margin: 0;
      padding: 0 16px 0 10px;

      border-radius: 50px;
      background-color: $gray-bg;

      transition: background-color .2s ease-out;

      @include media-down(laptop) {
        padding: 0 10px;
      }

      @include media-down(tablet) {
        width: 40px;
        height: 40px;
        padding: 0;
      }

      &:hover {
        background-color: darken($gray-bg, 5%);
      }

      span {
        display: inline-block;
        margin-left: 8px;

        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: $black-color;

        @include media-down(laptop) {
          display: none;
        }
      }
    }

    .btn.login {
      background-color: $yellow;

      &:hover {
        background-color: darken($yellow, 7%);
      }

      img {
        width: 24px;
      }

      span {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .btn.dropdown {
      display: none;

      background-color: $header-orange;

      transition: background-color .2s ease-out;

      &:hover {
        background-color: darken(#FF7233, 7%);
      }

      @include media-down(tablet) {
        display: inline-flex;
        width: 40px;
        height: 40px;
        position: relative;

        span {
          display: block;
          width: 16px;
          height: 3px;
          position: absolute;
          left: 12px;
          margin-left: 0;

          background-color: $white-color;

          transition: opacity .2s ease-out, transform .2s ease-out;

          @for $i from 1 through 3 {
            &:nth-of-type(#{$i}) {
              top: 12px + 6px * ($i - 1);
            }

            &.active:nth-of-type(#{$i}) {
              @if $i == 1 {
                top: 18px;
                transform: rotate(-45deg);
              } @else if $i == 2 {
                top: 18px;
                transform: rotate(45deg);
              } @else if $i == 3 {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 49;

    background-color: rgba(#000, .6);
    -webkit-backdrop-filter: blur(2.5px);
    backdrop-filter: blur(2.5px);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

</style>
