<template>
  <div class="dropdown-menu">
    <div class="dropdown-menu__wrapper">
      <div class="dropdown-menu__navigation">
        <ul>
          <li
            v-for="tab of navigationTabs"
            :key="tab.id"
            :class="{ 'active': tab.isActive }"
          >
            <button
              class="m-btn"
              @click="activeTab = tab.label"
            >
              <img
                v-if="tab.isActive"
                :src="tab.icon"
                alt="arrow"
              >
              {{ tab.label }}
            </button>
          </li>
        </ul>

        <div class="dropdown-menu__social">
          <p class="dropdown-menu__social-title">
            Напишите нам,
            <br>
            если есть вопросы
          </p>

          <div class="dropdown-menu__social-links">
            <a
              href="https://wa.me/79686286327"
              target="_blank"
              rel="nofollow"
            >
              <img
                src="/v2/header/old/dropdown/whatsapp.svg"
                alt="whatsapp"
              >
            </a>
            <a
              href="https://t.me/schoolsynergy_bot"
              target="_blank"
              rel="nofollow"
            >
              <img
                src="/v2/header/old/dropdown/telegram.svg"
                alt="telegram"
              >
            </a>
            <a
              href="mailto:nstolbova@synergy.ru"
              target="_blank"
              rel="nofollow"
            >
              <img
                src="/v2/header/old/dropdown/email.svg"
                alt="email"
              >
            </a>
          </div>
        </div>
      </div>

      <div
        v-if="activeTab"
        class="dropdown-menu__content"
      >
        <transition
          name="fade"
          mode="out-in"
        >
          <component
            :is="getComponentName"
            :key="activeTab"
            :content-type="getContentType"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownSchoolContent from './DropdownSchoolContent.vue';
import DropdownExternalContent from './DropdownExternalContent.vue';
import DropdownEgeOgeContent from './DropdownEgeOgeContent.vue';
import DropdownTutorsContent from './DropdownTutorsContent.vue';
import DropdownChildContent from './DropdownChildContent.vue';
import DropdownITContent from './DropdownITContent.vue';
import DropdownFreeContent from './DropdownFreeContent.vue';

import { CONTENT_TYPE } from './constants';

export default {
  name: 'DropdownMenu',
  components: {
    DropdownSchoolContent,
    DropdownExternalContent,
    DropdownEgeOgeContent,
    DropdownTutorsContent,
    DropdownChildContent,
    DropdownITContent,
    DropdownFreeContent,
  },

  data: () => ({
    activeTab: null,
  }),
  computed: {
    navigationTabs() {
      const tabsLabels = [
        { label: 'Домашняя школа', icon: '/v2/header/old/dropdown/arrow-school.svg' },
        { label: 'Экстернат', icon: '/v2/header/old/dropdown/arrow-external.svg' },
        { label: 'Подготовка к ОГЭ', icon: '/v2/header/old/dropdown/arrow-oge.svg' },
        { label: 'Подготовка к ЕГЭ', icon: '/v2/header/old/dropdown/arrow-ege.svg' },
        { label: 'Репетиторы', icon: '/v2/header/old/dropdown/arrow-tutors.svg' },
        { label: 'Развитие ребенка', icon: '/v2/header/old/dropdown/arrow-child.svg' },
        { label: 'IT-курсы для детей', icon: '/v2/header/old/dropdown/arrow-it.svg' },
        { label: 'Бесплатные материалы', icon: '/v2/header/old/dropdown/arrow-free.svg' },
      ];

      return tabsLabels.map((item, idx) => ({
        id: idx + 1,
        label: item.label,
        icon: item.icon,
        isActive: this.activeTab === item.label,
      }));
    },
    getComponentName() {
      switch (this.activeTab) {
        case 'Домашняя школа':
          return 'DropdownSchoolContent';
        case 'Экстернат':
          return 'DropdownExternalContent';
        case 'Подготовка к ЕГЭ':
        case 'Подготовка к ОГЭ':
          return 'DropdownEgeOgeContent';
        case 'Репетиторы':
          return 'DropdownTutorsContent';
        case 'Развитие ребенка':
          return 'DropdownChildContent';
        case 'IT-курсы для детей':
          return 'DropdownITContent';
        case 'Бесплатные материалы':
          return 'DropdownFreeContent';
        default:
          return null;
      }
    },
    getContentType() {
      switch (this.activeTab) {
        case 'Подготовка к ЕГЭ':
          return CONTENT_TYPE.ege;
        case 'Подготовка к ОГЭ':
          return CONTENT_TYPE.oge;
        default:
          return null;
      }
    },
  },

  created() {
    const pageName = this.$route.name;
    let activeTab = '';

    switch (pageName) {
      case 'index':
        activeTab = 'Домашняя школа';
        break;
      case 'online-school':
        activeTab = 'Домашняя школа';
        break;
      case 'externat':
        activeTab = 'Экстернат';
        break;
      case 'oge':
        activeTab = 'Подготовка к ОГЭ';
        break;
      case 'oge-subpage':
        activeTab = 'Подготовка к ОГЭ';
        break;
      case 'ege':
        activeTab = 'Подготовка к ЕГЭ';
        break;
      case 'ege-subpage':
        activeTab = 'Подготовка к ЕГЭ';
        break;
      case 'repetitor':
        activeTab = 'Репетиторы';
        break;
      case 'repetitor-anglijskij-yazyk':
        activeTab = 'Репетиторы';
        break;
      case 'repetitor-matematika':
        activeTab = 'Репетиторы';
        break;
      case 'repetitor-biologiya':
        activeTab = 'Репетиторы';
        break;
      case 'repetitor-fizika':
        activeTab = 'Репетиторы';
        break;
      case 'repetitor-himiya':
        activeTab = 'Репетиторы';
        break;
      case 'repetitor-informatika':
        activeTab = 'Репетиторы';
        break;
      case 'repetitor-istoriya':
        activeTab = 'Репетиторы';
        break;
      case 'repetitor-obshchestvoznanie':
        activeTab = 'Репетиторы';
        break;
      case 'repetitor-russkij-yazyk':
        activeTab = 'Репетиторы';
        break;
      case 'soft-skills':
        activeTab = 'Развитие ребенка';
        break;
      case 'documents':
        activeTab = 'Бесплатные материалы';
        break;
      default:
        activeTab = 'Домашняя школа';
    }
    this.activeTab = activeTab;
  },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  position: absolute;
  left: 0;
  top: 55px;
  padding-top: 10px;

  max-height: calc(100vh - 100px);
  overflow-y: auto;

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    padding: 30px;

    border-radius: 30px;
    border: 2px solid $gray-bg;
    background-color: $white-color;

    @include media-down(laptop) {
      padding: 20px;
    }
  }

  &__navigation {
    width: 235px;
    min-width: 235px;
    border-right: 2px solid rgba(36, 36, 36, 0.10);

    @media (max-width: 1220px) {
      min-width: 205px;
      width: auto;
    }

    @include media-down(laptop) {
      min-width: 190px;
      max-width: 190px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;

      li button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 35px;

        font-size: 20px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.4px;
        color: $black-color;

        cursor: pointer;
        transition: font-weight .2s ease-out;

        &:hover {
          font-weight: 700;
        }

        @media (max-width: 1220px) {
          height: 30px;

          font-size: 18px;
        }

        @include media-down(laptop) {
          font-size: 16px;
        }
      }

      li.active button {
        font-weight: 700;

        img {
          margin-right: 5px;
        }
      }
    }
  }

  &__social {
    margin-top: 40px;

    @media (max-width: 1220px) {
      margin-top: 20px;
    }

    &-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 100%;
      color: #919191;
    }

    &-links {
      display: flex;
      gap: 5px;
      margin-top: 10px;

      a {
        display: inline-block;
        width: 40px;
        height: 40px;
        overflow: hidden;

        border: 2px solid $black-color;
        border-radius: 10px;
        box-shadow: 2px 2px 0 0 $black-color;
        transform: translate(-2px, -2px);

        transition: transform .2s ease-out, box-shadow .2s ease-out;

        &:hover {
          transform: translate(0, 0);
          box-shadow: 0 0 0 0 $black-color;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  &__content {
    padding-left: 30px;

    @include media-down(laptop) {
      padding-left: 20px;

      height: calc(100vh - 130px);
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
