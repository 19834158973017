<template>
  <div
    class="dropdown-mobile"
    :class="{ 'active': activeTabs.length }"
  >
    <div class="dropdown-mobile__list">
      <div
        v-for="(item, idx) of navigationTabs"
        :key="item.id"
        class="dropdown-mobile__item"
        :class="{ 'active': item.isActive }"
      >
        <div
          :class="['dropdown-mobile__item-title', { 'active': item.isActive }]"
          role="button"
          tabindex="0"
          @click="toggleTab(item.label)"
        >
          <span>
            {{ item.label }}
          </span>

          <img
            :src="item.icon"
            alt="arrow"
          >
        </div>
        <div
          class="dropdown-mobile__item-content"
          :class="[
            'dropdown-mobile__item-content--' + idx,
            {
              'active' : item.isActive
            }
          ]"
          :style="{
            height: !item.isActive ? 0 : getHeight(idx),
            'margin-top': !item.isActive ? 0 : '10px',
          }"
        >
          <component
            :is="item.component"
            :content-type="item.contentType"
          />
        </div>
      </div>
    </div>

    <div class="dropdown-mobile__social">
      <p class="dropdown-mobile__social-title">
        Напишите нам,
        <br>
        если есть вопросы
      </p>

      <div class="dropdown-mobile__social-links">
        <a
          href="https://wa.me/79686286327"
          target="_blank"
          rel="nofollow"
        >
          <img
            src="/v2/header/old/dropdown/whatsapp.svg"
            alt="whatsapp"
          >
        </a>
        <a
          href="https://t.me/schoolsynergy_bot"
          target="_blank"
          rel="nofollow"
        >
          <img
            src="/v2/header/old/dropdown/telegram.svg"
            alt="telegram"
          >
        </a>
        <a
          href="mailto:nstolbova@synergy.ru"
          target="_blank"
        >
          <img
            src="/v2/header/old/dropdown/email.svg"
            alt="email"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownSchoolContent from './DropdownSchoolContent.vue';
import DropdownExternalContent from './DropdownExternalContent.vue';
import DropdownEgeOgeContent from './DropdownEgeOgeContent.vue';
import DropdownTutorsContent from './DropdownTutorsContent.vue';
import DropdownChildContent from './DropdownChildContent.vue';
import DropdownITContent from './DropdownITContent.vue';
import DropdownFreeContent from './DropdownFreeContent.vue';

export default {
  name: 'DropdownMenuMobile',
  components: {
    DropdownSchoolContent,
    DropdownExternalContent,
    DropdownEgeOgeContent,
    DropdownTutorsContent,
    DropdownChildContent,
    DropdownITContent,
    DropdownFreeContent,
  },

  data: () => ({
    activeTabs: [],
  }),
  computed: {
    navigationTabs() {
      const tabsLabels = [
        {
          label: 'Домашняя школа',
          icon: '/v2/header/old/dropdown/arrow-school.svg',
          component: 'DropdownSchoolContent',
        },
        {
          label: 'Экстернат',
          icon: '/v2/header/old/dropdown/arrow-external.svg',
          component: 'DropdownExternalContent',
        },
        {
          label: 'Подготовка к ОГЭ',
          icon: '/v2/header/old/dropdown/arrow-oge.svg',
          component: 'DropdownEgeOgeContent',
          contentType: 'oge',
        },
        {
          label: 'Подготовка к ЕГЭ',
          icon: '/v2/header/old/dropdown/arrow-ege.svg',
          component: 'DropdownEgeOgeContent',
          contentType: 'ege',
        },
        {
          label: 'Репетиторы',
          icon: '/v2/header/old/dropdown/arrow-tutors.svg',
          component: 'DropdownTutorsContent',
        },
        {
          label: 'Развитие ребенка',
          icon: '/v2/header/old/dropdown/arrow-child.svg',
          component: 'DropdownChildContent',
        },
        {
          label: 'IT-курсы для детей',
          icon: '/v2/header/old/dropdown/arrow-it.svg',
          component: 'DropdownITContent',
        },
        {
          label: 'Бесплатные материалы',
          icon: '/v2/header/old/dropdown/arrow-free.svg',
          component: 'DropdownFreeContent',
        },
      ];

      return tabsLabels.map((item, idx) => ({
        id: idx + 1,
        label: item.label,
        icon: item.icon,
        component: item.component,
        contentType: item.contentType || null,
        isActive: this.activeTabs.includes(item.label),
      }));
    },
  },
  methods: {
    toggleTab(tab) {
      if (this.activeTabs.includes(tab)) {
        this.activeTabs = this.activeTabs.filter((item) => item !== tab);
      } else {
        this.activeTabs = [
          ...this.activeTabs,
          tab,
        ];
      }
    },
    getHeight(index) {
      if (process.browser) {
        // ждем отрисовки DOM
        const accBody = document.querySelector(`.dropdown-mobile__item-content--${index}`)?.scrollHeight || 0;
        return `${accBody}px`;
      }

      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-mobile {
  display: block;
  position: absolute;
  left: 0;
  top: 44px;
  width: 100%;
  min-height: 517px;
  max-height: calc(100vh - 64px);
  overflow: auto;
  padding: 16px 11.5px;

  border-radius: 24px;
  border: 2px solid $black-color;
  background: $white-color;
  box-shadow: 2px 4px 0 0 $black-color;

  transition: min-height .2s ease-out;

  &__item {
    max-height: 49px;
    padding-bottom: 8px;
    overflow: hidden;

    border-bottom: 1px solid rgba(36, 36, 36, 0.10);

    transition: max-height .2s ease-out;

    &-title {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      span {
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 20px;
        font-weight: 600;
        line-height: 100%;
        color: $black-color;
      }

      img {
        margin-right: 5px;
        margin-top: 5px;
        transform: rotate(90deg);
        transition: transform .2s ease-out;
      }
    }
    &-title.active {
      img {
        transform: rotate(270deg);
      }
    }

    &-content {
      opacity: 0;
      pointer-events: none;
      transition: all .4s;
    }
    &-content.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
  &__item:last-of-type {
    border-bottom: none;
  }
  &__item.active {
    max-height: 1700px;
  }

  &__social {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    &-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 100%;
      color: $grey2;
    }

    &-links {
      display: flex;
      gap: 5px;
      margin-top: 10px;

      a {
        display: inline-block;
        width: 40px;
        height: 40px;
        overflow: hidden;

        border: 2px solid $black-color;
        border-radius: 10px;
        box-shadow: 2px 2px 0 0 $black-color;
        transform: translate(-2px, -2px);

        transition: transform .2s ease-out, box-shadow .2s ease-out;

        &:hover {
          transform: translate(0, 0);
          box-shadow: 0 0 0 0 $black-color;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
.dropdown-mobile.active {
  min-height: calc(100vh - 64px);
}
</style>
